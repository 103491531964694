import withRoot from "../utils/withRoot";
import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/Seo";
// import Card from "../components/Card";
import Page from "../components/Page";
// import HomeFeatures from "../components/HomeFeatures";
import Button from "@material-ui/core/Button";
// import Carousel from "../components/Carousel";
// import Avatar from "@material-ui/core/Avatar";
// import { Gift } from "mdi-material-ui";
import withStyles from "@material-ui/styles/withStyles";
// import irukaGIF from '../gifs/iruka-ani12.gif';
import Grid from "@material-ui/core/Grid";
// import BackgroundImage from 'gatsby-background-image';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import topImage from '../images/news.png';
import Img from "gatsby-image";
import { clearConfigCache } from "prettier";
import { mergeClasses } from "@material-ui/styles";
import TextField from '@material-ui/core/TextField';
import firebase from "gatsby-plugin-firebase";
// import { withFirebase } from "../components/WithFirebase";
import {navigate} from 'gatsby';

const flag = typeof window !== 'undefined';

// let auth = null;
let jQuery;
if (flag) {
    jQuery = require('jquery');
    // auth = firebase.auth();
}

const displayName = {
    "events": "イベント",
    "topics": "トピックス",
    "preRelease": "プレスリリース"
}

const colors = {
    'all': '#ffffff',
    'preRelease': '#d2b48c',
    'topics': '#8fbc8f',
    'events': '#6495ed'
}

const originArticles = [
    {
        'tag': 'topics',
        'date': '2017年12月15日',
        'content': '株式会社パッパーレとして、鈴木と西野で創業'
    },
    {
        'tag': 'topics',
        'date': '2018年10月1日',
        'content': '濱君が弊社に加わり、弊社が3名体制(鈴木、西野、濱)となった'
    },
    {
        'tag': 'events',
        'date': '2019年4月10日',
        'content': '国立がんセンター東病院様との一つ目のプロジェクトの成果をGoogleCloudNextで発表'
    },
    {
        'tag': 'events',
        'date': '2019年8月1日',
        'content': '新宿区大久保から、文京区本郷へ本店移転'
    },
    {
        'tag': 'events',
        'date': '2020年1月1日',
        'content': '国立がんセンター東病院様との二つ目のプロジェクトがスタート'
    },
    {
        'tag': 'events',
        'date': '2020年1月6日',
        'content': '株式会社パッパーレ、から、株式会社PAPPARE、へ商号変更'
    },
    {
        'tag': 'topics',
        'date': '2020年2月1日',
        'content': '逸見君が弊社に加わり、弊社が4名体制(鈴木、西野、濱、逸見)となった'
    },
    {
        'tag': 'topics',
        'date': '2020年8月1日',
        'content': '田村君が弊社に加わり、弊社が5名体制(鈴木、西野、濱、逸見、田村)となった'
    },
    {
        'tag': 'topics',
        'date': '2020年10月1日',
        'content': '伊藤君が弊社に加わり、弊社が6名体制(鈴木、西野、濱、逸見、田村、伊藤)となった'
    },
    {
        'tag': 'events',
        'date': '2021年5月31日',
        'content': '株式会社PAPPARE、から、AristoBrain株式会社、へ商号変更'
    },
    {
        'tag': 'events',
        'date': '2021年7月25日',
        'content': 'websiteをAristoBrain仕様に刷新'
    }
].reverse()

const defaultButton = createTheme({ palette: { primary: {main: colors.all}}})
const preButton = createTheme({ palette: { primary: {main: colors.preRelease}}})

const theme = createTheme({
    palette: {
        default: {
            main: '#ffffff'
        },
        primary: {
            main: colors.topics
        },
        secondary: {
            main: colors.events
        },
        info: {
            main: '#d2b48c'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 650,
            md: 1333,
            lg: 1920,
            xl: 2000,
        },
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: '#fff'
            }
        }
    }
});

const styles = theme => ({
    divider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    root: {
        fontWeight: "bold",
        // backgroundColor: '#000'
    },
    'input': {
        '&::placeholder': {
            color: '#fff',
            fontWeight: 900
        }
    }
    // gatsbyImageWrapper: {
    //   [theme.breakpoints.down('sm')]: {
    //     width: 'calc(100vw / 4)',
    //     height: 'calc(30vw / 6)'
    //   },
    //   [theme.breakpoints.down('xs')]: {
    //     width: 0,
    //     height: 0
    //   }
    // }
});

// const authIP = async(state) => {
//   var res = await fetch('/api/authIP', {
//     method: 'POST'
//   }).then((response) => {
//     // console.log(response)
//     return response.text();
//   });

//   // console.log(res)
//   if (res === 'Y') {
//     // console.log(state.state.disabled)
//     state.setState({disabled: false});
//   }
//   // console.log(state.state.disabled)
// }

class News extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            seo: false,
            articles: originArticles,
            animation: true
        }

        var url = this.props.location.href
        if (typeof(url) === 'string') {
            if (url.indexOf('staging') !== -1) {
                // console.log(this.state.disabled)
                this.state.disabled = true
                // console.log(this.state.disabled)
            } else {
                this.state.seo = true
            }
            // console.log(this.state.seo)
        }
        // console.log(this.props.data.testFixed)
        // authIP(this)

        this.renderNews = this.renderNews.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.state.disabled) {
            var res = await fetch('/api/authIP', {
                method: 'POST'
            }).then((response) => {
                // console.log(response)
                return response.text();
            });
            // console.log(res)
            if (res === 'Y') {
                // console.log(state.state.disabled)
                this.setState({disabled: false});
            }
        // console.log(state.state.disabled)
        }
        // console.log(jQuery('div.gatsby-image-wrapper'))
        jQuery('div.gatsby-image-wrapper').css('height', '');//.removeAttr('style');
        jQuery('div.gatsby-image-wrapper').css('width', '');
        jQuery('div.gatsby-image-wrapper').css('position', '');
    }

    renderSeo() {
        return (
            <Seo title="News">
                <meta
                    name="description"
                    content="AristoBrain株式会社のお知らせやプレスリリース、メディア掲載等"
                />
                <meta
                    name="keywords"
                    content="生命科学AI,生物科学AI,医療AI,行動予測AI,分野融合,企業変革,新規事業,データ分析,IoT,AI" 
                />
            </Seo>
        )
    }

    renderNews(article) {
        if (this.state.animation) {
            return(
                <div className='news-tag fade-in-news-context'>
                    <p className='border-radius' style={{backgroundColor: colors[article.tag]}}>{displayName[article.tag]}</p>
                    <h2>{article.date}</h2>
                    <p className='tbody-text'>{article.content}</p>
                </div>
            )
        } else {
            return(
                <div className='news-tag'>
                    <p className='border-radius' style={{backgroundColor: colors[article.tag]}}>{displayName[article.tag]}</p>
                    <h2>{article.date}</h2>
                    <p className='tbody-text'>{article.content}</p>
                </div>
            )
        };
    }

    handleOnSubmit(event) {
        var insertArticles = [];
        console.log(event.currentTarget)
        if (event.currentTarget.name === 'all') {
            insertArticles = originArticles
        } else {
            originArticles.map(article => {
                if (article.tag === event.currentTarget.name) {
                    insertArticles.push(article)
                    console.log(article)
                }
            })
        }
        this.setState({articles: insertArticles})
        if (this.state.animation) {
            this.setState({animation: false}); 
        };
    };


  render() {
    // const products = props.data.allMarkdownRemark.edges;
    const {disabled} = this.state;
    // console.log(disabled)
    // if (this.props.location.href.indexOf('staging') != -1) {
    //   disabled = true
    // }

    if (disabled) {
      // console.log(this.state.disabled)
      return (
        <h1>You can't enter in this pages.</h1>
      );
    } else {
      // console.log(theme.gatsbyImageWrapper)
    //   console.log(this.props.classes)
      return (
        <Page url={this.props.location.pathname}>
            <ThemeProvider theme={theme}>
                {this.state.seo && this.renderSeo()}
                <div className='fade-in-news-image animation-init' style={{backgroundImage: `url(${topImage})`, backgroundSize: 'cover', backgroundPosition: 'right top', display: 'flow-root', padding: '0 5% 10% 0'}}>
                    <div style={{margin: 0, padding: 0}}>
                        <div className='under-bar-title'>
                        <h1 className='fade-in-news-title'>News</h1>
                        <p className='fade-in-about-text'>
                            ニュース
                        </p>
                        </div>
                        <div className='under-bar-text'>
                            <div className='fade-in-news-button' style={{display: 'inline-block', width: '100%'}}>
                                <ThemeProvider theme={defaultButton}>
                                    <Button
                                        variant="outlined"
                                        className='colors-button'
                                        size="large"
                                        color='primary'
                                        name='all'
                                        onClick={this.handleOnSubmit}
                                    >
                                        すべて
                                    </Button>
                                </ThemeProvider>
                                <Button
                                    variant="contained"
                                    className='colors-button'
                                    size="large"
                                    color='primary'
                                    name='topics'
                                    onClick={this.handleOnSubmit}
                                >
                                    トピックス
                                </Button>
                                <Button
                                    variant="contained"
                                    className='colors-button'
                                    size="large"
                                    color='secondary'
                                    name='events'
                                    onClick={this.handleOnSubmit}
                                >
                                    イベント
                                </Button>
                                <ThemeProvider theme={preButton}>
                                    <Button
                                        variant="contained"
                                        className='colors-button'
                                        size="large"
                                        color='primary'
                                        name='preRelease'
                                        onClick={this.handleOnSubmit}
                                    >
                                        プレスリリース
                                    </Button>
                                </ThemeProvider>
                            </div>
                            {this.state.articles.map(article => this.renderNews(article))}
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </Page>
      );
    }
  }
};

export const query = graphql`
  query {
    allFile(filter: { extension: { eq: "jpg" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/products/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            image {
              publicURL
            }
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
  }
`;

export default withRoot(withStyles(styles)(News));